@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono');
.container{
	width: 100%;
	height: 100vh;
	background-color: #D7CCC8;
	background-color: rgb(0,0,0);
}
.main{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: url("/assets/bg.jpg");
	background: url("/assets/bg2.jpeg");
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
}
.intro{
	font-size: 16px;
	color: white;
}
.intro-container{
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	padding-left: 5px;
	width: 100%;
}
.promt{
	color: rgb(230,230,230);
	font-family: 'IBM Plex Mono', monospace;
}
.console{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	max-width: 550px;
	height: 330px;
	position: absolute;

	background-color: rgba(0,0,0,0.8);
	font-family: 'IBM Plex Mono', monospace;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	filter: drop-shadow(0 25px 45px rgba(#000, .40)) drop-shadow(0 0 2px rgba(#fff, .50));
}
.bar{
	background-color: rgb(180,180,180);
	height: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	padding-right: 10px;
	box-sizing: border-box;
	cursor: move;
}


.circle{
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: white;
	margin-left: 2px;
	margin-right: 2px;
}
.red{
	background-color: red;
	position: relative;
	cursor: pointer;
}
.yellow{
	background-color: yellow;
}
.green{
	background-color: green;
}

a.resume{
	color: white;
	text-decoration: none;
}
a.resume:hover{
	text-decoration: underline;
}

@media screen and (max-width: 780px) {
	.main{
		background-size: auto 100%;
	}
}



.tooltip{
	position: absolute;
	background-color: white;
	padding: 2px;
	border-radius: 3px;
	top: -25px;
	left: 5px;
	display: none;
}
.red:hover .tooltip{
	display: initial;
}


.red:hover .console{
	height: 0;
}



/************************
 * Hower Popup Sections	*
 ************************/

 a.resume{
 	position: relative;
 }

 .github-section{
 	width: 100px;
 	height: 100px;
 	background-color: white;
 	position: absolute;
 	top: -100px;
 	left: 0px;
 	display: none;
 	border: 1px solid rgba(11,11,11,0.5);
 	/*box-shadow: 2px 2px 10px rgba(255,255,255,0.3), -2px 0px 10px rgba(255,255,255,0.3) ;*/
 }
 .github-section i{
 	color: black;
 	font-size: 50px;
 }

 @keyframes popup{
 	from{
 		height: 0;
 		top: 0;
 	}
 	to{
 		height: 100px;
 		top: -100px;
 	}
 }

 .resume:hover .github-section{
 	/*position: relative;*/
 	display: flex;
 	align-items: center;
 	justify-content: center;
 	flex-direction: column;
 	color: black;
 	font-size: 10px;

 	/*Animations*/
 	animation-name: popup;
 	animation-duration: 150ms;
 	/*animation-timing-function: ease-;*/

 }

 .typed-cursor{
	 color: white;
	 font-size: 20px;
 }